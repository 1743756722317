
import Vue from "vue";
import action from "@/components/ActionBarCom.vue";
import pageCom from "@/components/PageCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import dialogCom from "@/components/ModelCom.vue";
import delCom from "@/components/DelDataCom.vue";
import { StoreTypes } from "@/assets/js/Types";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
export default Vue.extend({
  components: { action, pageCom, dialogCom, delCom },
  data() {
    return {
      deviceTypes: this.$store.state.device[StoreTypes.XC_DEVICE_TYPE].filter(
        (x: any) => x.categorySid == 1
      ),
      delUrl: Urls.DelPolicy,
      currentSid: "",
      items: [] as any[],
      total: 0,
      pageSize: 6,
      currentPage: 1,
      searchStr: null as any,
      dialogTitle: {
        title: "新增策略",
      },
      projectForm: {
        sid: null,
        isTimmer: 0,
        timmerType: 0,
        policyLoops: [],
      } as any,
      current: null as any,
      projects: [] as any[],
      step: 0,
      policySEDate: [] as any[],
    };
  },
  async mounted() {
    await this.select();
  },
  methods: {
    policyTypeChange() {
      if (this.projectForm.policyType == 0) {
        this.projectForm.isTimmer = 2;
      } else {
        if (this.projectForm.deviceTypeSid == 1 || this.projectForm.deviceTypeSid == 8) {
          this.projectForm.isTimmer = 1;
        } else {
          this.projectForm.isTimmer = 0;
        }
      }
    },
    deviceTypeChange() {
      if (this.projectForm.deviceTypeSid == 1) {
        this.projectForm.oneAction = 1;
      } else {
        this.projectForm.oneAction = 0;
      }
    },
    addDateTime() {
      if (!this.projectForm.policyLoops) {
        this.projectForm.policyLoops = [];
      }
      if (this.projectForm.policyLoops.length >= 6) {
        throw "该设备一个策略仅支持6个时间段";
      } else {
        this.projectForm.policyLoops.push({
          sid: Utils.GetGuid(),
          startTime: "",
          endTime: "",
          startAction: 0,
          endAction: 0,
          startVal: 0,
          endVal: 0,
          policyLoops: [
            {
              chanel: 1,
              enable: false,
            },
            {
              chanel: 2,
              enable: false,
            },
            {
              chanel: 3,
              enable: false,
            },
            {
              chanel: 4,
              enable: false,
            },
            {
              chanel: 5,
              enable: false,
            },
            {
              chanel: 6,
              enable: false,
            },
            {
              chanel: 7,
              enable: false,
            },
          ],
        });
        this.retSetOrder();
      }
    },
    addTimmer() {
      if (!this.projectForm.policyTimers) {
        this.projectForm.policyTimers = [];
      }
      this.projectForm.policyTimers.push({
        sid: Utils.GetGuid(),
        timeAction: 0,
        timmer: 0,
        timeVal: 0,
        order: 1,
      });
      this.retSetOrder();
    },
    removeTimmer(sid: string) {
      let index = -1;
      for (let i = 0; i < this.projectForm.policyLoops.length; i++) {
        if (this.projectForm.policyLoops[i].sid == sid) {
          index = i;
        }
      }
      this.projectForm.policyLoops.splice(index, 1);
      this.retSetOrder();
    },
    retSetOrder() {
      let i = 1;
      this.projectForm.policyLoops.forEach((x: any) => {
        x.order = i;
        i += 1;
      });
    },
    SEChange() {
      this.projectForm.policyStart = this.policySEDate[0];
      this.projectForm.policyEnd = this.policySEDate[1];
    },
    async oneNext(step: number) {
      this.step = step;
    },
    async select() {
      const actionBar: any = this.$refs.action;
      this.searchStr = actionBar.getSearch();
      if (Utils.StringIsNull(this.searchStr)) {
        this.searchStr = null;
      }
      const ret = await DeviceRequestBill.GetPolicys(
        this.searchStr,
        this.currentPage,
        this.pageSize,
        2
      );
      this.items = ret.data;
      this.total = ret.total;
    },
    currentChange(val: any) {
      this.current = val;
    },
    insert() {
      this.step = 0;
      this.projectForm = {
        sid: null,
        isTimmer: 0,
        timmerType: 0,
        deviceTypeSid: "",
        policyLoops: [],
      };
      this.dialogTitle.title = "新增策略";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    closeDialog() {
      const dialog: any = this.$refs.projectDialog;
      dialog.hiden();
    },
    async modify() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.dialogTitle.title = "修改策略";
      const reslut = await DeviceRequestBill.GetPolicyDetail(this.current.sid);
      this.projectForm = reslut;
      this.step = 0;
      this.policySEDate = [this.projectForm.policyStart, this.projectForm.policyEnd];
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
      console.log(this.projectForm);
    },
    del() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.currentSid = this.current.sid;
      const dialog: any = this.$refs.del;
      dialog.show();
    },
    ex() {
      //
    },
    inport() {
      //
    },
    search() {
      this.select();
    },
    searchChanged(str: string) {
      this.searchStr = str;
    },
    change(val: number, size: number) {
      this.currentPage = val;
      this.pageSize = size;
      this.select();
    },
    async onSubmit() {
      if (!this.projectForm.policyLoops) {
        this.projectForm.policyLoops = [];
      }
      if (this.projectForm.policyLoops && this.projectForm.policyLoops.length != 0) {
        this.projectForm.policyLoops.forEach((x: any) => {
          x.action = (x.action || 0) * 1;
          x.openTime = Utils.FormatDataFull(x.openTime);
          x.closeTime = Utils.FormatDataFull(x.closeTime);
        });
      }
      if (!this.projectForm.sid) {
        await HttpUtil.XCHttpPost(Urls.AddPolicy, this.projectForm, true, false);
      } else {
        await HttpUtil.XCHttpPost(Urls.UpdatePolicy, this.projectForm, true, false);
      }
      this.closeDialog();
      this.select();
    },
  },
});
