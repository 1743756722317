
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import pageCom from "@/components/PageCom.vue";
import Vue from "vue";
export default Vue.extend({
  components: { pageCom },
  props: ["dtype", "choosed"],
  data() {
    return {
      items: [] as any[],
      search: "",
      pageSize: 6,
      total: 0,
      page: 1,
      currentChange: null as any,
    };
  },
  methods: {
    async searchClick() {
      await this.getList();
    },
    async change(page: number, size: number) {
      this.page = page;
      this.pageSize = size;
      await this.getList();
    },
    handleSelectionChange(val: any) {
      this.$emit("selectChange", val);
    },
    initItem() {
      //
    },
    async getList(noFresh?: boolean) {
      if (noFresh && this.items.length > 0) {
        return;
      }
      const ret = await DeviceRequestBill.GetJGGateways(
        this.search,
        this.page,
        this.pageSize
      );
      this.total = ret.total;
      this.items = ret.data;
      const array: string[] = [];
      this.$nextTick(() => {
        if (this.choosed) {
          this.choosed.forEach((x: any) => {
            let value = this.items.find((y) => y.sid == x);
            if (value == null && x.sid) {
              value = this.items.find((y) => y.sid == x.sid);
            }
            if (value) {
              array.push(value);
              const table: any = this.$refs.table;
              table.toggleRowSelection(value);
            }
          });
        }
      });
    },
  },
});
