
import Vue from "vue";
import lineChar from "@/components/Charts/RLineChartCom.vue";
import box from "@/components/BoxCom.vue";
import mapCom from "@/components/MapCom.vue";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import { LoopDetail } from "@/assets/js/Model/ResponseModel";
import { EchartInitModel } from "@/assets/js/Model/InnerModel";
export default Vue.extend({
  components: { box, mapCom, lineChar },
  data() {
    return {
      onPic: require("@/assets/img/icon/sui_on.png"),
      offPic: require("@/assets/img/icon/sui_off.png"),
      mapData: [] as LoopDetail[],
      gyDialogShow: false,
      currentDevice: null as any,
      engerChart: {} as any,
    };
  },
  async mounted() {
    const map: any = this.$refs.map;
    map.init(true);
    await this.getData();
  },
  methods: {
    async getEngerByMonth() {
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetGatewayChartByMonth,
        this.currentDevice.sid,
        true,
        false
      );
      this.engerChart = ret;
      this.initEngChart();
    },
    initEngChart() {
      const labels: string[] = [];
      const datas: number[] = [];
      let title = "本年能耗分析";

      this.engerChart.forEach((x: any) => {
        labels.push(x.date);
        datas.push(x.energy);
        title = "本月能耗分析";
      });
      const dchart: any = this.$refs.engChart;
      const data: EchartInitModel = {
        Labels: labels,
        Data: [
          {
            Name: title,
            Data: datas,
            Arear: {
              opacity: 0.3,
            },
          },
        ],
        isXValue: false,
        isInBar: false,
        Style: 0,
        LegendConfig: {
          textStyle: {
            color: "#fff",
          },
          top: "10",
        },
      };
      dchart.setOption(data);
    },
    async getData() {
      const ret = await DeviceRequestBill.GetLoops(null, 1, 10000);
      ret.data.forEach((x: any) => {
        if (x.online == 1) {
          x.pic = this.onPic;
        } else {
          x.pic = this.offPic;
        }
      });
      this.addDevice(ret.data, 2, false);
      this.mapData = ret.data;
    },
    async dragend(dev: any, type: number, data: any) {
      const d: any[] = [
        {
          sid: dev.sid,
          lat: data.lnglat.lat,
          lng: data.lnglat.lng,
        },
      ];
      await HttpUtil.XCHttpPost(Urls.BatchPosition, d, true, false);
    },
    addDevice(dvs: any[], type: number, noClear?: boolean) {
      const map: any = this.$refs.map;
      if (!noClear) {
        map.clearDeviceLay();
      }
      const AMap: any = (window as any).AMap;
      const size = new AMap.Size(40, 60);
      if (type == 0) {
        map.addDevice(dvs, 0, "https://kingsunsmart.com/img/new/eded.png", size);
      } else if (type == 1) {
        map.addDevice(
          dvs,
          1,
          "https://kingsunsmart.com/img/stand/stand-normal.png",
          size
        );
      } else if (type == 2) {
        map.addDevice(dvs, 2, "https://kingsunsmart.com/img/stand/box.png", size);
      }
    },
    async devClick(device: any) {
      this.gyDialogShow = true;
      this.currentDevice = device;
      await this.getEngerByMonth();
    },
    mapClick() {
      this.gyDialogShow = false;
    },
    async loopChange(isOpen: boolean, sid: string) {
      const data = {
        loopSids: [sid],
        open: isOpen,
      };
      await HttpUtil.XCHttpPost(Urls.ControlLoop, data, true, false);
    },
  },
});
