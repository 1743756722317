
import Vue from "vue";
import action from "@/components/ActionBarCom.vue";
import pageCom from "@/components/PageCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import dialogCom from "@/components/ModelCom.vue";
import delCom from "@/components/DelDataCom.vue";
import deviceList from "../ShareComponents/DeviceChooseCom.vue";
import softList from "../ShareComponents/SoftChooseCom.vue";
import routerList from "../ShareComponents/RouterChooseCom.vue";
import jgList from "../ShareComponents/ChooseJGCom.vue";
// import loopList from "../ShareComponents/LoopChooseCom.vue";
import gatewayList from "../ShareComponents/GatewayChooseCom.vue";
import { StoreTypes } from "@/assets/js/Types";
import hardList from "../ShareComponents/HardChooseCom.vue";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import logCom from "../ShareComponents/TaskLogCom.vue";
export default Vue.extend({
  components: {
    action,
    pageCom,
    dialogCom,
    delCom,
    deviceList,
    softList,
    routerList,
    logCom,
    hardList,
    gatewayList,
    jgList,
  },
  data() {
    return {
      deviceTypes: this.$store.state.device[StoreTypes.XC_DEVICE_TYPE].filter(
        (x: any) => x.categorySid == 0 || x.categorySid == 1 || x.categorySid == 4
      ),
      devTypes: [] as any[],
      loopSavedItem: [] as any,
      loopCSavedItem: [] as any,
      devSavedItem: [] as any[],
      routerSavedItem: [] as any[],
      softSavedItem: [] as any[],
      devCSavedItem: [] as any[],
      routerCSavedItem: [] as any[],
      softCSavedItem: [] as any[],
      hardSavedItem: [] as any[],
      hardCSavedItem: [] as any[],
      getwaySavedItem: [] as any[],
      getwayCSavedItem: [] as any[],
      policys: [] as any[],
      activeName: "0",
      delUrl: Urls.DelTask,
      currentSid: "",
      items: [] as any[],
      total: 0,
      pageSize: 6,
      currentPage: 1,
      searchStr: null as any,
      dialogTitle: {
        title: "新增项目",
      },
      projectForm: {
        sid: null,
        isTimmer: 0,
        timmerType: 0,
        policyTimers: [],
        PolicyLoops: [],
      } as any,
      current: null as any,
      projects: [] as any[],
      step: 0,
      policySEDate: [] as any[],
      listPolicy: [] as any[],
      jgSavedItem: [] as any[],
      jgCSavedItem: [] as any[],
    };
  },
  async mounted() {
    this.devTypes = this.deviceTypes.filter((x: any) => x.categorySid == 0);
    await this.select();
  },
  methods: {
    down() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      HttpUtil.XCHttpPost(Urls.ExecPolicyTask, this.current.sid, true, false);
    },
    downPolicy() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      HttpUtil.XCHttpPost(Urls.ExecPolicyTaskFix, this.current.sid, true, false);
    },
    logClick(sid: string) {
      this.currentSid = sid;
      const log: any = this.$refs.logDialog;
      log.show();
    },
    logOpend() {
      const log: any = this.$refs.logList;
      log.getList();
    },
    closeLogDialog() {
      const log: any = this.$refs.logDialog;
      log.hiden();
    },
    jgSelectChange(val: any[]) {
      if (val.length == 0 && this.jgSavedItem.length > 1) {
        return;
      }
      this.jgCSavedItem = val;
      const di: any[] = [];
      this.jgCSavedItem.forEach((x) => {
        di.push(x.sid);
      });
      this.jgSavedItem = di;
    },
    devSelectChange(val: any[]) {
      if (val.length == 0 && this.devSavedItem.length > 1) {
        return;
      }
      this.devCSavedItem = val;
      const di: any[] = [];
      this.devCSavedItem.forEach((x) => {
        di.push(x.sid);
      });
      this.devSavedItem = di;
    },
    async deviceTypeChange() {
      if (this.listPolicy.length == 0) {
        this.listPolicy = (await DeviceRequestBill.GetPolicys(null, 1, 10000)).data;
      }
      if (this.projectForm.target == 0) {
        this.policys = this.listPolicy.filter(
          (x: any) => x.deviceTypeSid == this.projectForm.deviceTypeSid
        );
      } else if (this.projectForm.target == 2) {
        this.policys = this.listPolicy.filter((x: any) => x.isLightPolicy == 2);
      } else if (this.projectForm.target == 3) {
        this.policys = this.listPolicy.filter((x: any) => x.isLightPolicy == 3);
      }
    },
    softSelectChange(val: any[]) {
      this.softCSavedItem = val;
      this.softSavedItem = [];
      this.softCSavedItem.forEach((x) => {
        this.softSavedItem.push(x.sid);

        // if(!this.softSavedItem.find(y=>y==x.sid)){
        //     this.softSavedItem.push(x.sid);
        // }
      });
    },
    hardSelectChange(val: any[]) {
      this.hardCSavedItem = val;
      this.hardSavedItem = [];
      this.hardCSavedItem.forEach((x) => {
        this.hardSavedItem.push(x.sid);
      });
    },
    gatewaySelectChange(val: any[]) {
      this.getwayCSavedItem = val;
      this.getwaySavedItem = [];
      this.getwayCSavedItem.forEach((x) => {
        // if(!this.getwaySavedItem.find(y=>y==x.sid)){
        //     this.getwaySavedItem.push(x.sid);
        // }

        this.getwaySavedItem.push(x.sid);
      });
    },
    routerSelectChange(val: any[]) {
      this.routerCSavedItem = val;
      this.routerSavedItem = [];
      this.routerCSavedItem.forEach((x) => {
        this.routerSavedItem.push(x.sid);
      });
    },
    loopSelectChange(val: any[]) {
      this.loopCSavedItem = val;
      this.loopSavedItem = [];
      this.loopCSavedItem.forEach((x: any) => {
        this.loopSavedItem.push(x.sid);
      });
    },
    async targetChange(val?: number) {
      if (this.listPolicy.length == 0) {
        this.listPolicy = (await DeviceRequestBill.GetPolicys(null, 1, 10000)).data;
      }
      const value = val == 0 ? 0 : val == 2 ? 1 : 4;
      console.log(this.deviceTypes);
      this.devTypes = this.deviceTypes.filter((x: any) => x.categorySid == value);
      // if(this.devTypes.length!=0){
      //   this.projectForm.deviceTypeSid=this.devTypes[0].sid;
      // }
      await this.deviceTypeChange();
      // this.policys=this.listPolicy.filter((x:any)=>x.isLightPolicy==val);
      // val = val || this.projectForm.target;
      // val = (val || 0) * 1;
      // this.policys = this.listPolicy.filter((x) =>
      //   x.isLightPolicy == val &&
      //   (x.isPlatformPolicy == this.projectForm.runInPlatform) == true
      //     ? 1
      //     : 0
      // );
    },
    handleClick() {
      console.log(this.activeName, "tetstett");
      let list: any = null;
      if (this.activeName == "0") {
        list = this.$refs.devList;
        list.getList(true);
      }
      if (this.activeName == "1") {
        list = this.$refs.routerList;
        list.getList(true);
      }
      if (this.activeName == "2") {
        list = this.$refs.softList;
        list.getList(true);
      }
      if (this.activeName == "3") {
        list = this.$refs.hardList;
        list.getList(true);
      }
      if (this.activeName == "4") {
        list = this.$refs.gatewayList;
        list.getList(true);
      }
      if (this.activeName == "5") {
        list = this.$refs.loopList;
        list.getList(true);
      }
      if (this.activeName == "6") {
        list = this.$refs.jgList;
        list.getList(true);
      }
    },
    next(page: number) {
      this.step = page;
      if (this.projectForm.target == 2) {
        this.activeName = "4";
      } else if (this.projectForm.target == 3) {
        this.activeName = "6";
      } else {
        this.activeName = "0";
      }
      this.handleClick();
    },
    addTimmer() {
      if (!this.projectForm.policies) {
        this.projectForm.policies = [];
      }
      if (this.projectForm.runInPlatform && this.projectForm.policies.length >= 1) {
        throw "平台策略任务最多支持1个策略";
      }
      if (this.projectForm.policies.length >= 4) {
        throw "最多支持4个策略同时执行";
      }
      this.projectForm.policies.push({
        policySid: "",
        order: 0,
        priority: 0,
      });
      this.retSetOrder();
    },
    removeTimmer(sid: string) {
      let index = -1;
      for (let i = 0; i < this.projectForm.policies.length; i++) {
        if (this.projectForm.policies[i].sid == sid) {
          index = i;
        }
      }
      this.projectForm.policies.splice(index, 1);
      this.retSetOrder();
    },
    retSetOrder() {
      let i = 1;
      this.projectForm.policies.forEach((x: any) => {
        x.order = i;
        i += 1;
      });
    },
    async oneNext(step: number) {
      this.step = step;
    },
    async select() {
      const actionBar: any = this.$refs.action;
      this.searchStr = actionBar.getSearch();
      if (Utils.StringIsNull(this.searchStr)) {
        this.searchStr = null;
      }
      const ret = await DeviceRequestBill.GetTasks(
        this.searchStr,
        this.currentPage,
        this.pageSize
      );
      this.items = ret.data;
      this.total = ret.total;
    },
    currentChange(val: any) {
      this.current = val;
    },
    insert() {
      this.step = 0;
      this.projectForm = {
        sid: null,
        policies: [],
        target: 0,
      };
      this.projectForm.target = 0;
      this.targetChange(0);
      this.dialogTitle.title = "新增策略任务";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    closeDialog() {
      const dialog: any = this.$refs.projectDialog;
      dialog.hiden();
    },
    async modify() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.dialogTitle.title = "修改策略任务";
      const reslut = await DeviceRequestBill.GetTaskDetail(this.current.sid);
      this.projectForm = reslut;

      this.step = 0;
      this.loopSavedItem = reslut.loops;
      this.devSavedItem = reslut.lights;
      this.softSavedItem = reslut.softwareGroups;
      this.jgSavedItem = reslut.jgGateways;
      this.routerSavedItem = reslut.groups;
      this.getwaySavedItem = reslut.gateways;
      this.hardSavedItem = reslut.hardwareGroups;
      this.targetChange(this.current.target == 2 ? 1 : 0);
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    del() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.currentSid = this.current.sid;
      const dialog: any = this.$refs.del;
      dialog.show();
    },
    ex() {
      //
    },
    inport() {
      //
    },
    search() {
      this.select();
    },
    searchChanged(str: string) {
      this.searchStr = str;
    },
    change(val: number, size: number) {
      this.currentPage = val;
      this.pageSize = size;
      this.select();
    },
    async onSubmit() {
      this.projectForm.groups = this.routerSavedItem;
      this.projectForm.softwareGroups = this.softSavedItem;
      this.projectForm.lights = this.devSavedItem;
      this.projectForm.jGGateways = this.jgSavedItem;
      this.projectForm.gateways = this.getwaySavedItem;
      this.projectForm.loops = this.loopSavedItem;
      this.projectForm.hardwareGroups = this.hardSavedItem;
      if (!this.projectForm.sid) {
        await HttpUtil.XCHttpPost(Urls.AddTask, this.projectForm, true, false);
      } else {
        await HttpUtil.XCHttpPost(Urls.UpdateTask, this.projectForm, true, false);
      }
      this.closeDialog();
      this.select();
    },
  },
});
