
import Vue from "vue";
import addrCom from "@/components/AddrCom.vue";
import action from "@/components/ActionBarCom.vue";
import pageCom from "@/components/PageCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import dialogCom from "@/components/ModelCom.vue";
import delCom from "@/components/DelDataCom.vue";
import { StoreTypes, cookieTypes } from "@/assets/js/Types";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import uploadFile from "../ShareComponents/UploadFileCom.vue";
import chooseDialog from "../ShareComponents/ChooseDeviceCom.vue";
import { CtrlType } from "@/assets/js/Model/InnerModel";
import cookie from "../../../assets/js/CookieUtil";
export default Vue.extend({
  components: { pageCom },
  data() {
    return {
      noexcel: false,
      fileTitle: "请选择上传灯具Excel文件",
      delUrl: Urls.DelJGGateway,
      currentSid: "",
      items: [] as any[],
      total: 0 as number,
      pageSize: 6,
      currentPage: 1,
      searchStr: null as any,
      dialogTitle: {
        title: "新增控制器",
      },
      projectForm: {} as any,
      current: null as any,
      deviceTypes: this.$store.state.device[StoreTypes.XC_DEVICE_TYPE].filter(
        (x: any) => x.categorySid == 1
      ),
      routers: this.$store.state.device[StoreTypes.XC_DEVICE_ROUTER],
      boxs: this.$store.state.device[StoreTypes.XC_DEVICE_BOX],
      loops: [] as any[],
      Rloops: [] as any[],
      paraForm: {} as any,
      choosedItem: [] as any[],
      projectSid: "",
    };
  },
  async mounted() {
    await this.select();
  },
  methods: {
    change(val: number, size: number) {
      this.currentPage = val;
      this.pageSize = size;
      this.select();
    },
    handleSelectionChange(val: any) {
      this.choosedItem = [];
      val.forEach((x: any) => {
        this.choosedItem.push(x.sid);
      });
    },
    currentChange(val: any) {
      this.current = val;
    },
    async select() {
      const actionBar: any = this.$refs.action;
      // this.searchStr = actionBar.getSearch();
      if (Utils.StringIsNull(this.searchStr)) {
        this.searchStr = undefined;
      }
      const ret = await DeviceRequestBill.GetJGGateways(
        this.searchStr,
        this.currentPage,
        this.pageSize,
        null
      );
      this.items = ret.data;
      this.total = ret.total;
      console.log(this.items);
    },
    async open(action: number, serial?: string) {
      let obj = {
        serials: this.choosedItem,
        open: action == 1,
      };
      if (serial) {
        obj.serials = [serial];
      }
      await HttpUtil.XCHttpPost(Urls.JGOpen, obj, true, false);
      this.freshAsync();
    },
    async read() {
      var obj = {
        serials: this.choosedItem,
      };
      await HttpUtil.XCHttpPost(Urls.JGReadData, obj, true, false);
      this.freshAsync();
    },
    async asyncTime() {
      var obj = {
        serials: this.choosedItem,
      };
      await HttpUtil.XCHttpPost(Urls.JGSetLocalTime, obj, true, false);
      this.freshAsync();
    },
    freshAsync() {
      window.setTimeout(() => {
        this.select();
      }, 2000);
    },
  },
});
