
import Vue from "vue";
import box from "@/components/BoxCom.vue";
import mapCom from "@/components/MapCom.vue";
import searchInput from "@/components/SearchInputCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import dialogCom from "@/components/ModelCom.vue";
import { PagedResponse } from "@/assets/js/Model/ResponseModel";
import Utils from "@/assets/js/Utils";
import { CtrlType, EchartInitModel } from "@/assets/js/Model/InnerModel";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import { getColors } from "@/assets/js/EchartUtil";
import lineChar from "@/components/Charts/RLineChartCom.vue";
export default Vue.extend({
  components: { mapCom, box, searchInput, dialogCom, lineChar },
  data() {
    return {
      currentJgDev: {} as any,
      mapjgDialogShow: false,
      currentAction: 0,
      mapData: {
        routers: [] as any[],
        boxes: [] as any[],
        softs: [] as any[],
        jGGateways: [] as any[],
      },
      lightPageSize: 8000,
      lights: [] as any,
      lightsToMaped: [] as any,
      map: {} as any,
      loadingMap: false,
      polyData: [] as any[],
      editing: false,
      editAction: 0,
      batchType: 0,
      batchDevices: [] as any[],
      groupLable: "请选择分组",
      lightCrl: {
        devices: [],
        action: 0,
        value: 0,
      },
      batchTitle: {
        title: "更新",
      },
      batchForm: {
        devices: [],
        sid: "" as string,
      },
      batchSids: [] as any[],
      currentDev: {} as any,
      mapDialogShow: false,
      siwtchValue: true,
      sliderValue: 20,
      lightAlarm: {} as any,
      gyDialogShow: false,
      Colors: getColors(),
      currentGateway: {} as any,
      engerChart: {} as any,
      lightOnTime: {} as any,
      showLightOnTime: true,
      search: "",
      boxToMap: [] as any,
      jgToMap: [] as any,
    };
  },
  async mounted() {
    const map: any = this.$refs.map;
    map.init(false);
    this.map = map;
    await this.select();
    await this.getLightOnTime();
    this.setTimeAuto();
  },
  methods: {
    async select() {
      await this.GetLightDevices();
      await this.GetOtherData();
    },
    searchChanged(search: string) {
      this.search = search;
    },
    async GetOtherData() {
      var data: any = await HttpUtil.XCHttpPost(Urls.GetMapOtherMapData, "", true, false);
      this.mapData.boxes = data.boxes;
      this.mapData.routers = data.routers;
      this.mapData.softs = data.softs;
      this.mapData.jGGateways = data.jgGateways;
      console.log(this.mapData, data);
    },
    setTimeAuto() {
      window.setInterval(() => {
        const date = new Date();
        this.lightOnTime.timeNow = `${date
          .getHours()
          .toString()
          .padStart(2, "0")}:${date
          .getMinutes()
          .toString()
          .padStart(2, "0")} ${date.getSeconds().toString().padStart(2, "0")}`;
      }, 1000);
    },
    async GetLightDevices() {
      var obj = {
        page: 1,
        pageSize: this.lightPageSize,
      };
      var devs: any = await HttpUtil.XCHttpPost(Urls.GetMapLights, obj, true, false);
      this.lights = devs.items;
      if (this.lights.length == this.lightPageSize) {
        let pages: number = Math.round(devs.totalCount / this.lightPageSize);
        for (let i = 1; i <= pages; i++) {
          console.log(i, pages);
          obj.page = i + 1;
          var d: any = await HttpUtil.XCHttpPost(Urls.GetMapLights, obj, true, false);
          d.items.forEach((item: any) => {
            this.lights.push(item);
          });
        }
      }
      this.lightsToMaped = this.lights;
      console.log(this.lightsToMaped.length);
      this.addLightToMap();
    },
    async getLightState() {
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetLightState,
        this.currentDev.sid,
        true,
        false
      );
      this.lightAlarm = ret;
    },
    async switchJgChange(val: any) {
      var obj = {
        serials: [this.currentDev.sid],
        open: val == 1,
      };
      await HttpUtil.XCHttpPost(Urls.JGOpen, obj, true, false);
    },
    async switchChange(val: any) {
      if (!this.currentDev || !this.currentDev.ctrlAddr) {
        throw "请选择一个单灯控制器";
      }
      const ids = [this.currentDev.ctrlAddr];
      const type = val ? CtrlType.开灯 : CtrlType.关灯;
      await DeviceRequestBill.CtrLightBySignal(ids, type, 0);
    },
    async sliderChange(val: any) {
      if (!this.currentDev || !this.currentDev.ctrlAddr) {
        throw "请选择一个单灯控制器";
      }
      const ids = [this.currentDev.ctrlAddr];
      const type = CtrlType.调光;
      await DeviceRequestBill.CtrLightBySignal(ids, type, val);
    },
    async freshPower() {
      //
    },
    async dragend(dev: any, type: number, data: any) {
      let d: any[];
      if (data.lnglat) {
        d = [
          {
            sid: dev.sid,
            lat: data.lnglat.lat,
            lng: data.lnglat.lng,
          },
        ];
        // map.reAddDeviceLay(dev, type, data.lnglat.lat, data.lnglat.lng);
      } else if (data.Of) {
        d = [
          {
            sid: dev.sid,
            lat: data.Of.lat,
            lng: data.Of.lng,
          },
        ];
        // map.reAddDeviceLay(dev, type, data.Of.lat, data.Of.lng);
      }
      await HttpUtil.XCHttpPost(Urls.BatchPosition, d, true, false);
    },
    async devClick(device: any, type: number, data: any, marker: any) {
      this.showLightOnTime = false;
      const map: any = this.$refs.map;
      // map.resetAlarm(device.sid);
      this.currentDev = device;
      if (
        this.currentDev.deviceTypeSid == "1" ||
        this.currentDev.deviceTypeSid == "2" ||
        this.currentDev.deviceTypeSid == "8"
      ) {
        this.sliderValue = this.currentDev.light;
        this.siwtchValue = this.currentDev.open;
        this.mapDialogShow = true;
        this.gyDialogShow = false;
        this.mapjgDialogShow = false;
        this.initLinechar();
        await this.getLightState();
      } else if (this.currentDev.deviceTypeSid == "4") {
        this.gyDialogShow = true;
        this.mapDialogShow = false;
        this.mapjgDialogShow = false;
        var req = {
          page: 1,
          pageSize: 1,
          conditions: [
            {
              field: "sid",
              value: this.currentDev.sid,
              conditionType: 0,
            },
          ],
        };
        const ret: any = await HttpUtil.XCHttpPost(Urls.GetLoopList, req, true, false);
        if (ret.data && ret.data.length != 0) {
          this.currentGateway = ret.data[0];
          await this.getEngerByMonth();
        } else {
          this.currentGateway = {
            gatewayLoops: [],
          };
        }
      } else if (this.currentDev.deviceTypeSid == "10") {
        this.gyDialogShow = false;
        this.mapDialogShow = false;
        this.mapjgDialogShow = true;
      }
    },
    async getEngerByMonth() {
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetGatewayChartByMonth,
        { sid: this.currentGateway.sid, month: null },
        true,
        false
      );
      this.engerChart = ret;
      this.initEngChart();
    },
    initEngChart() {
      const labels: string[] = [];
      const datas: number[] = [];
      let title = "本年能耗分析";

      this.engerChart.forEach((x: any) => {
        labels.push(x.date);
        datas.push(x.energy);
        title = "本月能耗分析";
      });
      const dchart: any = this.$refs.engChart;
      const data: EchartInitModel = {
        Labels: labels,
        Data: [
          {
            Name: title,
            Data: datas,
            Arear: {
              opacity: 0.3,
            },
          },
        ],
        isXValue: false,
        isInBar: false,
        Style: 0,
        LegendConfig: {
          textStyle: {
            color: "#fff",
          },
          top: "10",
        },
      };
      dchart.setOption(data);
    },
    polygonClick() {
      this.batchSids = [];
      if (this.batchType == 0) {
        this.batchTitle.title = "批量更新设备路段";
        this.groupLable = "请选择路段";
        this.mapData.routers.forEach((x: any) => {
          this.batchSids.push({
            sid: x.sid,
            name: x.groupName,
          });
        });
      } else if (this.batchType == 1) {
        this.batchTitle.title = "批量更新设备电箱";
        this.groupLable = "请选择电箱";
        this.mapData.boxes.forEach((x: any) => {
          this.batchSids.push({
            sid: x.sid,
            name: x.deviceName,
          });
        });
      } else if (this.batchType == 2) {
        this.batchTitle.title = "批量更新设备分组";
        this.groupLable = "请选择软件分组";
        this.mapData.softs.forEach((x: any) => {
          this.batchSids.push({
            sid: x.sid,
            name: x.groupName,
          });
        });
      }
      if (this.batchType <= 2) {
        // this.batchTitle.title=this.batchType==0?'批量更新设备路段':this.batchType==1?'批量更新设备电箱':'批量更新设备分组';
        this.batchForm.sid = "";
        const dialog: any = this.$refs.dialogGroup;
        dialog.show();
      } else if (this.batchType == 3) {
        const dialog: any = this.$refs.dialogLightCtrl;
        dialog.show();
      }
    },
    async getLightOnTime() {
      this.lightOnTime = await HttpUtil.XCHttpPost(Urls.GetLightOnTime, "", true, false);
    },
    mapClick(e: any) {
      this.mapDialogShow = false;
      this.gyDialogShow = false;
      this.mapjgDialogShow = false;
      if (!this.editing) {
        return;
      }
      this.polyData.push({
        lat: e.lnglat.lat,
        lng: e.lnglat.lng,
      });
      if (this.editAction == 0 || this.editAction == 1) {
        this.drawPolcy(this.polyData);
      }
    },
    drawPolcy(data: any[], moveData?: any) {
      const map: any = this.$refs.map;
      map.drawPolyLine(data, moveData);
    },
    searchClick(search: string) {
      this.search = search;
      if (this.search && this.search.length != 0) {
        if (this.currentAction == 0) {
          console.log(this.lights, this.currentAction);
          this.lightsToMaped = this.lights.filter((x: any) => {
            if (x.deviceName && x.deviceName.includes(this.search)) {
              return true;
            }
            if (x.groupName && x.groupName.includes(this.search)) {
              return true;
            }
            if (x.ctrlAddr && x.ctrlAddr.includes(this.search)) {
              return true;
            }
            return false;
          });
          this.addLightToMap();
        } else if (this.currentAction == 2) {
          this.boxToMap = this.mapData.boxes.filter((x: any) => {
            if (x.deviceName && x.deviceName.includes(this.search)) {
              return true;
            }
            if (x.addr && x.addr.includes(this.search)) {
              return true;
            }
            if (x.gatewayAddress && x.gatewayAddress.includes(this.search)) {
              return true;
            }
            return false;
          });
          this.addBoxToMap();
        } else if (this.currentAction == 3) {
          this.jgToMap = this.mapData.jGGateways.filter((x: any) => {
            if (x.deviceName && x.deviceName.includes(this.search)) {
              return true;
            }
            if (x.ctrlAddr && x.ctrlAddr.includes(this.search)) {
              return true;
            }
          });
          this.addJgToMap();
        }
      }
    },
    mapChange(type: number) {
      this.mapDialogShow = false;
      this.mapjgDialogShow = false;
      this.gyDialogShow = false;
      this.currentAction = type;
      if (type == 0) {
        this.map.clear();
        this.addLightToMap();
      } else if (type == 2) {
        this.map.clear();
        this.boxToMap = this.mapData.boxes;
        this.addBoxToMap();
      } else if (type == 3) {
        this.map.clear();
        this.jgToMap = this.mapData.jGGateways;
        this.addJgToMap();
      }
    },
    async initLinechar() {
      if (!this.currentDev || !this.currentDev.ctrlAddr) {
        throw "请选择一个单灯控制器";
      }
      const req = {
        page: 1,
        pageSize: 15,
        conditions: [
          {
            field: "deviceSid",
            value: this.currentDev.sid,
            conditionType: 0,
          },
        ],
        orderByConditions: [
          {
            field: "sid",
            asc: false,
          },
        ],
      };
      const ret: any = await HttpUtil.XCHttpPost(Urls.GetHistoryDatas, req, true, false);
      const chart: any = this.$refs.lineChar;
      let lables: string[] = [];
      const data1: number[] = [];
      const data2: number[] = [];
      const data3: number[] = [];
      ret.data.forEach((x: any) => {
        const date = new Date(x.updateTime);
        lables.push(Utils.FormatData(date));
        data1.push(x.voltage);
        data2.push(x.current);
        data3.push(x.power);
      });
      const data: EchartInitModel = {
        NoAuto: true,
        Colors: [this.Colors[0], this.Colors[1], this.Colors[2]],
        Labels: lables,
        Data: [
          {
            Name: "电压",
            Data: data1,
            Arear: {
              opacity: 0.3,
            },
          },
          {
            Name: "电流",
            Data: data2,
            Arear: {
              opacity: 0.3,
            },
          },
          {
            Name: "功率",
            Data: data3,
            Arear: {
              opacity: 0.3,
            },
          },
        ],
        Style: 0,
        LegendConfig: {
          textStyle: {
            color: "#fff",
          },
          top: "10",
        },
      };
      chart.setOption(data);
    },
    mapRightClick(e: any) {
      if (this.editing) {
        if (this.editAction == 0) {
          if (this.polyData.length == 1) {
            this.polyData.push({ lat: e.lnglat.lat, lng: e.lnglat.lng });
          }
          this.drawPolcy(this.polyData);
          this.saveLightData();
        } else if (this.editAction == 1) {
          this.clearPolcy();
          this.drawPolygon(this.polyData);
          const list: any[] = [];
          for (let i = 0; i < this.lightsToMaped.length; i++) {
            if (
              Utils.PointerInPolygon(this.polyData, {
                lat: this.lightsToMaped[i].lat,
                lng: this.lightsToMaped[i].lng,
              })
            ) {
              list.push(this.lightsToMaped[i]);
            }
          }
          if (list == null || list.length == 0) {
            this.editing = false;
            this.clearPolygon();
            this.clearPolcy();
            throw "当前范围没有设备";
          }
          this.batchDevices = list;
          this.editing = false;
        }
      } else {
        this.clearPolygon();
        this.clearPolcy();
      }
    },
    clearPolygon() {
      const map: any = this.$refs.map;
      map.removPolygon();
    },
    drawPolygon(data: any[]) {
      const map: any = this.$refs.map;
      map.drawPolygon(data);
    },
    mapMouseMove(e: any) {
      if (!this.editing) {
        return;
      }
      if (this.polyData.length == 0) {
        return;
      } else {
        this.drawPolcy(this.polyData, {
          lat: e.lnglat.lat,
          lng: e.lnglat.lng,
        });
      }
    },
    clearPolcy() {
      const map: any = this.$refs.map;
      map.removePolyLine();
    },
    saveLightData() {
      const pos = Utils.GetNextPosition(this.polyData, this.lightsToMaped.length);
      for (let i = 0; i < this.lightsToMaped.length; i++) {
        if (i < pos.length) {
          this.lightsToMaped[i].lat1 = this.lightsToMaped[i].lat;
          this.lightsToMaped[i].lng1 = this.lightsToMaped[i].lng;
          this.lightsToMaped[i].lat = pos[i].lat;
          this.lightsToMaped[i].lng = pos[i].lng;
        } else {
          this.lightsToMaped[i].lat1 = this.lightsToMaped[i].lat;
          this.lightsToMaped[i].lng1 = this.lightsToMaped[i].lng;
          this.lightsToMaped[i].lat = pos[pos.length - 1].lat;
          this.lightsToMaped[i].lng = pos[pos.length - 1].lng;
        }
      }
      this.addLightToMap();
      Utils.ConfirmMsg(
        this,
        "是否更新设备位置信息",
        async () => {
          const data: any[] = [];
          this.lightsToMaped.forEach((x: any) => {
            data.push({
              sid: x.sid,
              lat: x.lat,
              lng: x.lng,
            });
          });
          await HttpUtil.XCHttpPost(Urls.BatchPosition, data, true, false);
          const line = JSON.stringify(this.polyData);
          this.editing = false;
          this.clearPolygon();
          this.clearPolcy();
        },
        () => {
          this.clearPolygon();
          this.clearPolcy();
          this.editing = false;
          this.lightsToMaped.forEach((x: any) => {
            x.lat = x.lat1;
            x.lng = x.lng1;
          });
          this.ligthChange(0);
        }
      );
    },
    ligthChange(type: number, state?: string) {
      this.loadingMap = true;
      new Promise((res, rej) => {
        if (type == 0) {
          if (!state) {
            this.lightsToMaped = this.lights;
            this.addLightToMap();
          } else {
            if (state == "online") {
              this.lightsToMaped = this.lights.filter((x: any) => x.online == 1);
            } else if (state == "offline") {
              this.lightsToMaped = this.lights.filter((x: any) => x.online == 0);
            }
            if (state == "alarm") {
              this.lightsToMaped = this.lights.filter((x: any) => x.state == 1);
            }
          }
        } else if (type == 1) {
          this.lightsToMaped = this.lights.filter((x: any) => x.boxSid == state);
        } else if (type == 2) {
          this.lightsToMaped = this.lights.filter((x: any) => x.groupSid == state);
        } else if (type == 3) {
          var soft = this.mapData.softs.find((x) => x.sid == state);
          if (soft.items) {
            this.lightsToMaped = this.lights.filter(
              (x: any) => soft.items.indexOf(x) >= 0
            );
          }
        }
        this.addLightToMap();
        this.loadingMap = false;
      });
    },
    editPostion() {
      this.editAction = 0;
      this.polyData = [];
      this.clearPolygon();
      this.clearPolcy();
      this.map.clear();
      this.editing = true;
    },
    ligthGroup(type: number, sub: number) {
      if (type == 0) {
        this.editAction = 1;
        this.editing = true;
        this.polyData = [];
        this.clearPolygon();
        this.clearPolcy();
        this.batchType = sub;
      }
    },
    addLightToMap() {
      this.map.clear();
      this.map.addDeviceAuto(this.lightsToMaped, 0, { w: 25, h: 38 });
    },
    addBoxToMap() {
      this.map.clear();
      this.map.addDeviceAuto(this.boxToMap, 2, { w: 30, h: 45 });
    },
    addJgToMap() {
      this.map.clear();
      this.map.addDeviceAuto(this.jgToMap, 2, { w: 30, h: 45 });
    },
    boxOnline(type: number) {
      this.boxToMap = this.mapData.boxes.filter((x) => x.online == type);
      this.addBoxToMap();
    },
    jgOnline(type: number) {
      //
    },
    submitCtlCancel() {
      const dialog: any = this.$refs.dialogLightCtrl;
      dialog.hiden();
      this.clearPolygon();
    },
    async submitCtrl() {
      const data: any = {
        serials: [] as any[],
      };
      let url = Urls.CtrSwitch;
      this.batchDevices.forEach((x) => {
        data.serials.push(x.ctrlAddr);
      });
      if (this.lightCrl.action == 0 || this.lightCrl.action == 1) {
        data.open = this.lightCrl.action == 0 ? true : false;
      } else {
        url = Urls.CtrDim;
        data.value = this.lightCrl.value;
      }
      await HttpUtil.XCHttpPost(url, data, true, false);
      this.submitCtlCancel();
    },
    submitCancel() {
      const dialog: any = this.$refs.dialogGroup;
      dialog.hiden();
      this.clearPolygon();
    },
    async loopChange(isOpen: boolean, sid: string) {
      const data = {
        loopSids: [sid],
        open: isOpen,
      };
      await HttpUtil.XCHttpPost(Urls.ControlLoop, data, true, false);
    },
    async submitUpdate() {
      if (Utils.StringIsNull(this.batchForm.sid)) {
        throw "没有选择分组";
      }
      const device: any[] = [];
      this.batchDevices.forEach((x: any) => {
        device.push(x.sid);
      });
      let url = Urls.BatchGroup;
      let data: any = {};
      if (this.batchType == 0) {
        data = {
          groupSid: this.batchForm.sid,
          devices: device,
        };
      } else if (this.batchType == 1) {
        url = Urls.BatchBox;
        data = {
          boxSid: this.batchForm.sid,
          devices: device,
        };
      } else if (this.batchType == 2) {
        url = Urls.BatchSoftGroup;
        data = [
          {
            sid: this.batchForm.sid,
            devices: device,
          },
        ];
      }
      if (device.length != 0) {
        await HttpUtil.XCHttpPost(url, data, true, false);
        this.batchDevices.forEach((x: any) => {
          if (this.batchType == 0) {
            x.groupSid = this.batchForm.sid;
          } else if (this.batchType == 1) {
            x.boxSid = this.batchForm.sid;
          }
        });
        this.submitCancel();
      } else {
        throw "没有选择设备";
      }
    },
  },
});
