
import Vue from "vue";
import buttonCom from "@/components/ButtonCom.vue";
import searchInput from "@/components/SearchInputCom.vue";
export default Vue.extend({
  components: { buttonCom, searchInput },
  props: {
    select: Boolean,
    insert: Boolean,
    del: Boolean,
    modify: Boolean,
    ex: Boolean,
    inport: Boolean,
    power: Boolean,
    role: Boolean,
    edit: Boolean,
    down: Boolean,
    temp: Boolean,
    exp: Boolean,
    imp: Boolean,
    batch: Boolean,
    ota: Boolean,
    showOnline: Boolean,
  },
  data() {
    return {
      search: "",
      online: 2,
    };
  },
  methods: {
    searchChanged(search: string) {
      this.search = search;
      this.$emit("searchChanged", search);
    },
    searchClick(search: string) {
      this.search = search;
      this.$emit("search", this.search);
    },
    getSearch() {
      if (this.showOnline) {
        return {
          searchStr: this.search,
          online: this.online,
        };
      } else {
        return this.search;
      }
    },
  },
});
