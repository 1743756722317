
import Vue from "vue";
import action from "@/components/ActionBarCom.vue";
import pageCom from "@/components/PageCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import dialogCom from "@/components/ModelCom.vue";
import delCom from "@/components/DelDataCom.vue";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import deviceList from "../ShareComponents/DeviceChooseCom.vue";
import loopList from "../ShareComponents/LoopChooseCom.vue";
export default Vue.extend({
  components: { action, pageCom, dialogCom, delCom, loopList, deviceList },
  data() {
    return {
      delUrl: Urls.DelSoft,
      currentSid: "",
      items: [] as any[],
      total: 0,
      pageSize: 6,
      currentPage: 1,
      searchStr: null as any,
      dialogTitle: {
        title: "新增项目",
      },
      projectForm: {} as any,
      current: null as any,
      projects: [] as any[],
      deviceType: -1,
      choosedItem: [] as any,
      savedItem: [] as string[],
      savedDevice: [] as any[],
      deleteDevice: [] as any[],
      addDevices: [] as any[],
      dtype: 0,
      addLoops: [] as any[],
    };
  },
  async mounted() {
    // await this.getProjects();
    await this.select();
  },
  methods: {
    loopSelectChange(val: any) {
      this.addLoops = val;
    },
    addDevice() {
      if (this.current.groupType != 2) {
        var dialog: any = this.$refs.addDevice;
        dialog.show();
      } else {
        var dialog2: any = this.$refs.loopDialog;
        dialog2.show();
      }
    },
    async loopAddClick() {
      let data = {
        sid: this.current.sid,
        devices: [] as any[],
      };
      this.addLoops.forEach((element) => {
        data.devices.push(element.sid);
      });
      await HttpUtil.XCHttpPost(Urls.AddLoopsToSoftGroup, data, true, false);
      const detail: any = await HttpUtil.XCHttpPost(
        Urls.GetSoft,
        this.current.sid,
        true,
        false
      );
      this.savedDevice = detail.subDevices;
    },
    deviceSelectChange(Val: any) {
      this.addDevices = Val;
    },
    async deviceAddClick() {
      let data = {
        sid: this.current.sid,
        devices: [] as any[],
      };
      this.addDevices.forEach((element) => {
        data.devices.push(element.sid);
      });
      await HttpUtil.XCHttpPost(Urls.AddDeviceToSoftGroup, data, true, false);
      const detail: any = await HttpUtil.XCHttpPost(
        Urls.GetSoft,
        this.current.sid,
        true,
        false
      );
      this.savedDevice = detail.subDevices;
    },
    deviceOpend() {
      const dialgo: any = this.$refs.deviceList;
      dialgo.getList();
    },
    deviceHandleSelectionChange(val: any) {
      this.deleteDevice = val;
    },
    selectChange(val: any) {
      this.choosedItem = val;
      // if(!this.choosedItem){
      //   this.choosedItem=[];
      // }
      // val.forEach((item:any) => {
      //   if(!this.choosedItem.find(x=>x==item.sid)){
      //     this.choosedItem.push(item);
      //   }
      // });
      // this.choosedItem.forEach((item:any) => {
      //   this.savedItem
      // });
    },
    opened() {
      // const list: any = this.$refs.devList;
      // list.getList();
    },
    openLoop() {
      const list: any = this.$refs.loopList;
      list.getList();
    },
    async getProjects() {
      const dfata: any = {
        page: 1,
        pageSize: 1000,
        pagination: true,
      };
      const ret: any = await HttpUtil.XCHttpPost(Urls.GetProjects, dfata, true, false);
      this.projects = ret.data;
    },
    async edit() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.deviceType = this.current.groupType;
      this.dtype = this.deviceType;
      const detail: any = await HttpUtil.XCHttpPost(
        Urls.GetSoft,
        this.current.sid,
        true,
        false
      );
      this.savedDevice = detail.subDevices;
      this.savedItem = detail.devices;

      const dialog: any = this.$refs.editDialog;
      dialog.show();

      // else{
      // const dialog: any = this.$refs.loopDialog;
      // dialog.show();

      // }
    },
    async onEditSubmit() {
      let data = {
        sid: this.current.sid,
        devices: [] as any[],
      };
      this.deleteDevice.forEach((element) => {
        data.devices.push(element.sid);
      });
      if (this.current.groupType != 2) {
        await HttpUtil.XCHttpPost(Urls.RemoveDeviceToSoftGroup, data, true, false);
      } else {
        await HttpUtil.XCHttpPost(Urls.RemoveLoopsToSoftGroup, data, true, false);
      }
      this.closeEditDialog();
    },
    closeEditDialog() {
      const dialog: any = this.$refs.editDialog;
      dialog.hiden();
    },
    closeLoopDialog() {
      const dialog: any = this.$refs.loopDialog;
      dialog.hiden();
    },
    async select() {
      const actionBar: any = this.$refs.action;
      this.searchStr = actionBar.getSearch();
      const ret = await DeviceRequestBill.GetSofts(
        this.searchStr,
        this.currentPage,
        this.pageSize
      );
      this.total = ret.total;
      this.items = ret.data;
    },
    closeDeviceList() {
      var dialog: any = this.$refs.addDevice;
      dialog.hiden();
    },
    currentChange(val: any) {
      this.current = val;
    },
    insert() {
      this.current = null;
      this.projectForm = {
        sid: null,
      };
      this.dialogTitle.title = "新增软件分组";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    closeDialog() {
      const dialog: any = this.$refs.projectDialog;
      dialog.hiden();
    },
    modify() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.projectForm = this.current;
      this.dialogTitle.title = "修改软件分组";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    del() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.currentSid = this.current.sid;
      const dialog: any = this.$refs.del;
      dialog.show();
    },
    ex() {
      //
    },
    inport() {
      //
    },
    search() {
      this.select();
    },
    searchChanged(str: string) {
      this.searchStr = str;
    },
    change(val: number, size: number) {
      this.currentPage = val;
      this.pageSize = size;
      this.select();
    },
    async onSubmit() {
      if (!this.projectForm.sid) {
        await HttpUtil.XCHttpPost(Urls.AddSoft, this.projectForm, true, false);
      } else {
        await HttpUtil.XCHttpPost(Urls.UpdateSoft, this.projectForm, true, false);
      }
      this.closeDialog();
      this.select();
    },
  },
});
