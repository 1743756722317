
import Vue from "vue";
import uploadPic from "@/components/UploadPicCom.vue";
import addrCom from "@/components/AddrCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import { cookieTypes, StoreTypes } from "@/assets/js/Types";
export default Vue.extend({
  components: { uploadPic, addrCom },
  data() {
    return {
      form: {
        projectName: "",
      } as any,
    };
  },
  async mounted() {
    await this.select();
  },
  watch: {
    "$store.state.freshProject"() {
      this.select();
    },
  },
  methods: {
    async select() {
      const psid = this.$store.state.project[StoreTypes.XC_PROJECT_CURRENT].sid;
      const ret = await HttpUtil.XCHttpPost(Urls.GetProject, psid, true, false);
      console.log(ret);
      this.form = ret;
      const uploadPic: any = this.$refs.uploadPic;
      uploadPic.initPic(this.form.logoPic);
      const addr: any = this.$refs.addr;
      addr.init({
        addr: this.form.projectAddr,
        lat: this.form.lat,
        lng: this.form.lng,
      });
    },
    logoUpload(file: string) {
      this.form.logoPic = file;
    },
    async onSubmit() {
      const addr: any = this.$refs.addr;
      const data = addr.getData();
      this.form.projectAddr = data.addr;
      this.form.lat = data.lat;
      this.form.lng = data.lng;
      await HttpUtil.XCHttpPost(Urls.UpdateProject, this.form, true, false);
      await this.select();
    },
  },
});
