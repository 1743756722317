
import Vue from "vue";
import sliderCom from "@/components/SliderCom.vue";
import box from "@/components/BoxCom.vue";
import allCom from "./JGComponents/DeviceCompoent.vue";
// import allLoop from "./CtrComponents/DeviceCompoent.vue";
import routerCom from "./CtrComponents/RouterCom.vue";
import groupCom from "./JGComponents/DeviceCtrlCompoent.vue";
import dialogCom from "@/components/ModelCom.vue";
import { StoreTypes } from "@/assets/js/Types";
import Urls from "@/assets/js/Urls";
import { CtrlType } from "@/assets/js/Model/InnerModel";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import HttpUtil from "@/assets/js/HttpUtil";
export default Vue.extend({
  components: { sliderCom, box, allCom, routerCom, groupCom },
  data() {
    return {
      currentSid: "",
      selectType: 0,
      slider: [] as any[],
      chooseLoops: [] as any[],
      sunFormat: {
        sids: [] as string[],
        downOffset: 0,
        riseOffset: 0,
        enable: false,
      },
    };
  },
  mounted() {
    this.getMenu();
    this.change("0");
  },
  methods: {
    change(sid: string) {
      this.currentSid = sid;
    },

    getMenu() {
      this.slider.push({
        sid: "0",
        name: "设备管理",
      }),
        this.slider.push({
          sid: "1",
          name: "设备控制",
        });
      // this.slider.push({
      //   sid: "2",
      //   name: "定时策略",
      // }),
      // this.slider.push({
      //   sid: "3",
      //   name: "策略任务",
      // });
    },
  },
});
