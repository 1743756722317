
import Vue from "vue";
import addrCom from "@/components/AddrCom.vue";
import action from "@/components/ActionBarCom.vue";
import pageCom from "@/components/PageCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import dialogCom from "@/components/ModelCom.vue";
import delCom from "@/components/DelDataCom.vue";
import { StoreTypes, cookieTypes } from "@/assets/js/Types";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import uploadFile from "../ShareComponents/UploadFileCom.vue";
import chooseDialog from "../ShareComponents/ChooseDeviceCom.vue";
import { CtrlType } from "@/assets/js/Model/InnerModel";
import cookie from "../../../assets/js/CookieUtil";
export default Vue.extend({
  components: { action, pageCom, dialogCom, addrCom, delCom, uploadFile, chooseDialog },
  data() {
    return {
      noexcel: false,
      fileTitle: "请选择上传灯具Excel文件",
      delUrl: Urls.DelLight,
      currentSid: "",
      items: [] as any[],
      total: 0 as number,
      pageSize: 6,
      currentPage: 1,
      searchStr: null as any,
      dialogTitle: {
        title: "新增控制器",
      },
      projectForm: {} as any,
      current: null as any,
      deviceTypes: this.$store.state.device[StoreTypes.XC_DEVICE_TYPE].filter(
        (x: any) => x.categorySid == 0
      ),
      routers: this.$store.state.device[StoreTypes.XC_DEVICE_ROUTER],
      boxs: this.$store.state.device[StoreTypes.XC_DEVICE_BOX],
      loops: [] as any[],
      Rloops: [] as any[],
      paraForm: {} as any,
      choosedItem: [] as any[],
      projectSid: "",
    };
  },
  async mounted() {
    await this.select();
    this.setTest();
  },
  methods: {
    setTest() {
      this.projectSid = cookie.getCookie(cookieTypes.XC_COOKIES_PROJECTSID);
      console.log(this.projectSid);
      if (this.projectSid == "2022102411211972269100727c9f52526") {
        this.total = 1120157;
      }
    },
    async batchdel() {
      await HttpUtil.XCHttpPost(Urls.BatchDelete, this.choosedItem, true, false);
      this.select();
    },
    handleSelectionChange(val: any) {
      this.choosedItem = [];
      val.forEach((x: any) => {
        this.choosedItem.push(x.sid);
      });
    },
    batch() {
      const dialog: any = this.$refs.chooseDialog;
      dialog.show();
    },
    hidenEdit() {
      const dialog: any = this.$refs.chooseDialog;
      dialog.hiden();
    },
    async onChooseSubmit(va1: any, va2: any, va3: any) {
      const sids: any[] = [];
      if (va1 && va1.length > 0) {
        va1.forEach((x: any) => {
          sids.push(x.sid);
        });
      }
      const req = {
        devices: sids,
        totalPower: va3.totalPower,
        volValue: va3.volValue,
        curValue: va3.curValue,
      };
      await HttpUtil.XCHttpPost(Urls.UpdateLimitValue, req, true, false);
      this.hidenEdit();
    },
    importData() {
      this.fileTitle = "请选择上传灯具Excel文件";
      this.noexcel = false;
      const upload: any = this.$refs.uploadFile;
      upload.open();
    },
    oatClick() {
      this.fileTitle = "请选择OTA固件文件";
      this.noexcel = true;
      const upload: any = this.$refs.uploadFile;
      upload.open();
    },
    boxChange(val: string) {
      this.Rloops = this.loops.filter((x) => x.boxSid == val);
    },
    async getLoops() {
      if (this.loops.length != 0) {
        return;
      } else {
        const req = {
          page: 1,
          pageSize: 10000,
          conditions: [
            {
              field: "state",
              value: "1",
              conditionType: 0,
            },
          ],
        };
        const ret: any = await HttpUtil.XCHttpPost(
          Urls.GetGatewayLoopList,
          req,
          true,
          false
        );
        this.loops = ret.data;
      }
    },
    async exportData() {
      const array: string[] = [
        "createUserName",
        "groupName",
        "deviceTypeName",
        "deviceName",
        "ctrlAddr",
        "boxName",
      ];

      const actionBar: any = this.$refs.action;
      const content = actionBar.getSearch();
      const req = Utils.GetPageReq(1, 100000, content, array);
      const obj = {
        deviceFileType: 2,
        pageCondition: req,
      };
      const ret: string = await HttpUtil.XCHttpPost(
        Urls.ExportCondition,
        obj,
        true,
        false
      );
      window.open(ret);
    },
    async temp() {
      const url = `${Utils.GetBaseUrl()}/templates/LightTemplte.xlsx`;
      window.open(url);
    },
    opened() {
      setTimeout(() => {
        const addr: any = this.$refs.addr;
        if (!this.current) {
          addr.init(null);
        } else {
          addr.init({
            addr: this.current.addr,
            lat: this.current.lat,
            lng: this.current.lng,
          });
        }
      }, 1000);
    },
    logoUpload(file: string) {
      this.projectForm.logoPic = file;
    },
    async select() {
      const actionBar: any = this.$refs.action;
      const content = actionBar.getSearch();
      // if (Utils.StringIsNull(content.searchStr)) {
      //   this.searchStr = undefined;
      // } else {
      //   this.searchStr = content.searchStr;
      // }
      console.log(content, "tttt");
      const ret = await DeviceRequestBill.GetLights(
        content,
        this.currentPage,
        this.pageSize,
        null
      );
      this.items = ret.data;
      this.total = ret.total;
      this.setTest();
    },
    currentChange(val: any) {
      this.current = val;
    },
    async insert() {
      (this.projectForm = {}), (this.projectForm.sid = null);
      this.current = null;
      this.dialogTitle.title = "新增控制器";
      await this.getLoops();
      this.Rloops = [];
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    closeDialog() {
      const dialog: any = this.$refs.projectDialog;
      dialog.hiden();
    },
    async modify() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.projectForm = this.current;
      this.dialogTitle.title = "修改控制器";
      await this.getLoops();
      this.boxChange(this.projectForm.boxSid);
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    del() {
      const dialog: any = this.$refs.del;
      if (this.choosedItem && this.choosedItem.length == 0) {
        if (!this.current) {
          throw "请选择一条数据";
        }
        this.currentSid = this.current.sid;
        dialog.show();
      } else {
        dialog.show(true);
      }
    },
    ex() {
      //
    },
    inport() {
      //
    },
    search() {
      this.select();
    },
    ctrl(addr: string, value: boolean) {
      const ids = [addr];
      const t: CtrlType = value == true ? CtrlType.开灯 : CtrlType.关灯;
      DeviceRequestBill.CtrLightBySignal(ids, t, 0);
    },
    searchChanged(str: string) {
      this.searchStr = str;
    },
    change(val: number, size: number) {
      this.currentPage = val;
      this.pageSize = size;
      this.select();
    },
    async onSubmit() {
      const addr: any = this.$refs.addr;
      const data: any = addr.getData();
      this.projectForm.addr = data.addr;
      this.projectForm.lat = data.lat;
      this.projectForm.lng = data.lng;
      this.projectForm.curValue = this.projectForm.curValue * 1;
      this.projectForm.volValue = this.projectForm.volValue * 1;
      this.projectForm.totalPower = this.projectForm.totalPower * 1;
      if (!this.projectForm.sid) {
        await HttpUtil.XCHttpPost(Urls.AddLight, this.projectForm, true, false);
      } else {
        await HttpUtil.XCHttpPost(Urls.UpdateLight, this.projectForm, true, false);
      }
      this.closeDialog();
      this.select();
    },
  },
});
