
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      searchContent: "",
    };
  },
  methods: {
    searchChanged() {
      this.$emit("searchChanged", this.searchContent);
    },
    search() {
      this.$emit("search", this.searchContent);
    },
  },
});
